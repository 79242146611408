import {
  Card,
  CardFooter,
  CardHeader,
  CloseButton,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";
import { deleteData } from "utils/firebaseUtils";
function InternalCard({ id = "", name = "",  datentime }) {
  const navigate = useHistory();
  const toast = useToast();
  const cardColor = useColorModeValue("white", "gray.700");
  const date = new Date(datentime);
  const formattedDate = date.toLocaleString();
  const handleDelete = () => {
    deleteData(`internal`,id).then(() => {
      toast({
        position: "bottom-right",
        title: "College Deleted",
        variant: "subtle",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      navigate.replace(`/admin/internal-details`);
    });
  };
  // Pass the computed styles into the `__css` prop
  return (
    <Card
      variant="elevated"
      bg={cardColor}
      cursor="pointer"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      borderRadius="15px"
    >
      <CardHeader py="1rem">
        <Flex spacing="4">
          <Text
            fontSize="lg"
            my="auto"
            casing="uppercase"
            onClick={() => navigate.push(`/admin/edit-internal/${id}`)}
          >
            {name}
          </Text>
          <CloseButton
            onClick={() => handleDelete()}
            color="red.400"
            ml={"auto"}
            size="lg"
          />
        </Flex>
      </CardHeader>
      <CardFooter mt="auto">
        <Text mt="auto" ml="auto" p="0.5rem" fontSize="small" color="gray.400">
          {formattedDate}
        </Text>
      </CardFooter>
    </Card>
  );
}

export default InternalCard;
