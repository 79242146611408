import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import InputField from "components/Forms/InputField";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getDocumentRealtime, sendData } from "utils/firebaseUtils";

export default function AddInternal() {
  const navigate = useHistory();
  const match = useRouteMatch();
  const toast = useToast();
  const [infoKey, setInfoKey] = useState("");
  const [infoVal, setInfoVal] = useState("");
  const internalRef = useRef(null);

  const [data, setData] = useState({
    id: "",
    title: "",
    info: [],
  });
  useEffect(() => {
    getDocumentRealtime("internal", match.params.id, setData);
  }, []);
  const addToInfo = () => {
    setData((prev) => ({
      ...prev,
      info: [...prev.info, { title: infoKey, value: infoVal }],
    }));
    internalRef.current.reset();
    setInfoKey("");
    setInfoVal("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendData("internal", match.params.id, {
      id: match.params.id,
      title: match.params.title,
      info: data.info,
      state: match.params.state,
    }).then(() => {
      toast({
        position: "bottom-right",
        title: "Internal Details Created",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      navigate.goBack();
    });
  };
  const cardColor = useColorModeValue("white", "gray.700");

  const handleDelete = (index) => {
    if (index !== -1) {
      data.info.splice(index, 1);
      setData((prev) => ({ ...prev, information: data.info }));
    }
  };
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        mt="26px"
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Flex direction="column">
              <Text
                fontSize="lg"
                fontWeight="bold"
                pb=".5rem"
                casing={"uppercase"}
              >
                Add Internal Details
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <form
              id="add-internal"
              ref={internalRef}
              style={{ width: "100%" }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Grid templateColumns={"repeat(1,1fr)"} gap="1rem" width="100%">
                <Grid
                  templateColumns="2fr 2fr 1fr"
                  gap="1rem"
                  mb="1rem"
                  width="100%"
                >
                  <InputField
                    type="text"
                    label="Information Key"
                    value={infoKey}
                    state={infoKey}
                    onChange={setInfoKey}
                    disabled={false}
                    required={false}
                  />
                  <InputField
                    type="text"
                    label="Information Value"
                    value={infoVal}
                    state={infoVal}
                    onChange={setInfoVal}
                    disabled={false}
                    required={false}
                  />
                  <Button mt="auto" bg="teal.400" onClick={() => addToInfo()}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              <Wrap spacing="10px">
                {data.info.map((ele, i) => (
                  <WrapItem>
                    <Tag bg="teal.800" variant="subtle">
                      <TagLabel>{`${ele.title}:${ele.value}`}</TagLabel>
                      <TagCloseButton
                        color="red.400"
                        onClick={() => handleDelete(i)}
                      />
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
            </form>
          </CardBody>
          <CardFooter>
            <Button bg="teal.400" type="submit" form="add-internal">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
