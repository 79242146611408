import { Flex, Grid, Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import NewCard from "components/Card/NewCard";
import TestimonialCard from "components/Card/TestimonialCard";

import { getDataRealtime } from "utils/firebaseUtils";

export default function Testimonials() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getDataRealtime("Testimonials", "", (e) => {
      setData(Object.values(e), setLoading);
    });
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      {loading ? (
        <Progress colorScheme="teal" size="xs" isIndeterminate />
      ) : (
        <Grid
          templateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          gap="24px"
        >
          {data.map((node, i) => (
            <TestimonialCard key={i} {...node} />
          ))}
          <NewCard section="testimonial" />
        </Grid>
      )}
    </Flex>
  );
}
