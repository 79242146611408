import {
  Card,
  CardFooter,
  CardHeader,
  CloseButton,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { BsFillShieldLockFill } from "react-icons/bs";

import { useHistory } from "react-router-dom";
import { deleteDataRealtime } from "utils/firebaseUtils";
function SectionCard({
  id = "",
  name = "",
  state = "",
  datentime,
}) {
  const navigate = useHistory();
  const toast = useToast()
  const cardColor = useColorModeValue("white", "gray.700");
  const date = new Date(datentime);
  const formattedDate = date.toLocaleString();
  const handleDelete = (e) => {
    e.stopPropagation();
    deleteDataRealtime(`colleges/${state}/${id}`).then(() => {
      toast({
        position: "bottom-right",
        title: "College Deleted",
        variant: "subtle",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    });;
  };
  // Pass the computed styles into the `__css` prop
  return (
    <Card
      variant="elevated"
      bg={cardColor}
      cursor="pointer"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      borderRadius="15px"
      onClick={() => navigate.push(`/admin/edit-college/${state}/${id}`)}
    >
      <CardHeader py="1rem">
        <Flex spacing="4">
          <Text fontSize="lg" my="auto" casing="uppercase">
            {name}
          </Text>
          <CloseButton
            onClick={(e) => handleDelete(e)}
            color="red.400"
            ml={"auto"}
            size="lg"
          />
        </Flex>
      </CardHeader>
      <CardFooter mt="auto">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigate.push(`/admin/add-internal/${id}/${name}/${state}`);
          }}
          variant="ghost"
          borderRadius="100%"
          icon={<BsFillShieldLockFill />}
        ></IconButton>
        <Text mt="auto" ml="auto" p="0.5rem" fontSize="small" color="gray.400">
          {formattedDate}
        </Text>
      </CardFooter>
    </Card>
  );
}

export default SectionCard;
