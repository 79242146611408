import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Grid,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import InputField from "components/Forms/InputField";
import { downloadCSV } from "utils/FileUtils";
import { getCollectionRealtimeRealtime } from "utils/firebaseUtils";

import TableRow from "components/Tables/TableRow";
import { BsFiletypeCsv } from "react-icons/bs";
import { TbDatabaseOff } from "react-icons/tb";

export default function PlanC() {
  const textColor = useColorModeValue("gray.700", "white");
  const cardColor = useColorModeValue("white", "gray.700");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("None");
  const headers = {
    Name: "name",
    Status: "status",
    Score: "score",
    Email: "email",
    Phone: "phone",
    "Alt. Phone": "altPhone",
    "Submitted At": "datentime",
  };

  const filterData = (filter) => {
    setFilter(filter);
    if (filter !== "None") {
      setLoading(true);
      getCollectionRealtimeRealtime(
        "plans",
        setData,
        setLoading,
        ["plan", "==", "C"],
        ["status", "==", filter]
      );
    } else {
      getCollectionRealtimeRealtime("plans", setData, setLoading, [
        "plan",
        "==",
        "C",
      ]);
    }
  };
  useEffect(() => {
    getCollectionRealtimeRealtime("plans", setData, setLoading, [
      "plan",
      "==",
      "C",
    ]);
  }, []);
  return (
    <>
      {loading ? (
        <Center mt="6rem">
          <Spinner size="lg" colorScheme="teal" />
        </Center>
      ) : (
        <Card
          mt="5rem"
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Grid templateColumns={{ sm: "1fr", md: "7fr 3fr" }}>
              <Text fontSize="xl" color={textColor} casing="uppercase">
                These are the users who opted Plan C
              </Text>

              <Grid templateColumns="3fr 1fr" gap="1rem">
                <InputField
                  type="select"
                  onChange={(e) => {
                    filterData(e);
                  }}
                  value={filter}
                  state={filter}
                  options={[
                    "None",
                    "Initiated",
                    "Not Picked",
                    "Low Chance",
                    "High Chance",
                    "Done",
                  ]}
                  disabled={false}
                />
                <IconButton
                  m="auto"
                  onClick={() =>
                    downloadCSV(
                      data,
                      [
                        "id",
                        "name",
                        "email",
                        "phone",
                        "altPhone",
                        "status",
                        "score",
                        "plan",
                        "datentime",
                      ],
                      "PlanC.csv"
                    )
                  }
                  variant="ghost"
                  size="lg"
                  borderRadius="100%"
                  icon={<BsFiletypeCsv />}
                />
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody overflowX="scroll">
            <Table variant="simple" color={textColor}>
              {data.length > 0 ? (
                <>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      {Object.keys(headers).map((caption, idx) => {
                        return (
                          <Th color="gray.400" key={idx}>
                            {caption}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((row, i) => {
                      return (
                        <TableRow
                          col="plans"
                          key={i}
                          keys={Object.values(headers)}
                          data={row}
                        />
                      );
                    })}
                  </Tbody>
                </>
              ) : (
                <Flex
                  h="40vh"
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    sx={{
                      color: "gray.400",
                      fontSize: "4xl",
                      textAlign: "center",
                    }}
                  >
                    <TbDatabaseOff />
                  </Text>
                  <Text sx={{ color: "gray.400", fontSize: "xl" }}>
                    NO ENTRIES
                  </Text>
                </Flex>
              )}
            </Table>
          </CardBody>
        </Card>
      )}
    </>
  );
}
