import AddCollege from "components/Forms/AddCollege";
import AddInternal from "components/Forms/AddInternal";
import AddTestimonial from "components/Forms/AddTestimonial";
import EditCollege from "components/Forms/EditCollege";
import EditContact from "components/Forms/EditContact";
import EditInternal from "components/Forms/EditInternal";
import EditTestimonial from "components/Forms/EditTestimonial";
import { DocumentIcon, RocketIcon } from "components/Icons/Icons";
import { BsFillEnvelopeFill, BsFillPersonVcardFill } from "react-icons/bs";
import { FaUniversity } from "react-icons/fa";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import Colleges from "views/Dashboard/Colleges";
import College from "views/Dashboard/Colleges/colleges";
import Contact from "views/Dashboard/Contact";
import InternalDetails from "views/Dashboard/InternalDetails";
import Testimonials from "views/Dashboard/Testimonials";
var dashRoutes = [
  {
    path: "/states",
    name: "Colleges",
    rtlName: "",
    icon: <FaUniversity color="inherit" />,
    component: Colleges,
    layout: "/admin",
  },
  {
    path: "/college/:state",
    name: "Colleges Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: College,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/add-college/:state",
    name: "Add Colleges",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: AddCollege,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/edit-college/:state/:id",
    name: "Edit Colleges",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: EditCollege,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/internal-details",
    name: "Internal Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: InternalDetails,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/add-internal/:id/:title/:state",
    name: "Add Internal Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: AddInternal,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/edit-internal/:id",
    name: "Edit Internal Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: EditInternal,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/add-testimonial",
    name: "Add Testimonial",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: AddTestimonial,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/edit-testimonial/:id",
    name: "Edit Testimonial Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: EditTestimonial,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/edit-contact/:id",
    name: "Edit Contact Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: EditContact,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/testimonials",
    name: "Testimonials",
    rtlName: "",
    icon: <BsFillPersonVcardFill color="inherit" />,
    component: Testimonials,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Contact",
    rtlName: "",
    icon: <BsFillEnvelopeFill color="inherit" />,
    component: Contact,
    layout: "/admin",
  },
  {
    path: "/signin",
    name: "Sign In",
    rtlName: "لوحة القيادة",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/signup",
    name: "Sign Up",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    redirect: true,
  },
];

export default dashRoutes;
