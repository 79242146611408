import { Flex, Grid, Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import InternalCard from "components/Card/InternalCard";
import { getCollectionRealtimeRealtime } from "utils/firebaseUtils";

export default function  InternalDetails() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
   useEffect(() => {
        getCollectionRealtimeRealtime("internal", setData,setLoading);
  }, []);
// console.log(data)
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      {loading ? (
        <Progress colorScheme="teal" size="xs" isIndeterminate />
      ) : (
        <Grid
          templateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          gap="24px"
        >
          {data.map((row) => (
            <InternalCard id={row.id} name={row.title} datentime={row.datentime} />
          ))}
        </Grid>
      )}
    </Flex>
  );
}
