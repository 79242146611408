import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdAdd } from "react-icons/md";
function NewCard({ section = "" ,state=""}) {
  // Pass the computed styles into the `__css` prop
  return (
    <Link
      height="100%"
      to={`/admin/add-${section}/${state}`}
    >
      <Button
        height="100%"
        width="100%"
        py="2.5rem"
        variant="ghost"
        colorScheme="teal"
      >
        <MdAdd size="3rem" />
        <p>
          Add{" "}
          {section.length > 0 && section[0].toUpperCase() + section.slice(1)}
        </p>
      </Button>
    </Link>
  );
}

export default NewCard;
