import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Grid,
  Image,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { uuidv4 } from "@firebase/util";
import InputField from "components/Forms/InputField";
import { useHistory, useRouteMatch } from "react-router-dom";
import { sendDataRealtime, uploadImage } from "utils/firebaseUtils";

export default function AddCollege() {
  const formRef = useRef(null);
  const navigate = useHistory();
  const toast = useToast();
  const match = useRouteMatch();
  const [infoKey, setInfoKey] = useState("");
  const [infoVal, setInfoVal] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [des, setDes] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(match.params.state);
  const [info, setInfo] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let uid = uuidv4();
    sendDataRealtime(`colleges/${state}`, uid, {
      id: uid,
      title: name,
      description: des,
      image: image,
      information: info,
    }).then(() => {
      toast({
        position: "bottom-right",
        title: "College Created",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setName("");
      setDes("");
      setState("");
      setInfo([]);
      navigate.replace(`/admin/states`);
    });
  };
  const cardColor = useColorModeValue("white", "gray.700");
  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const infos = [
    { label: "AC or NAC", type: "select", options: ["AC", "Non AC", "Both"] },
    { label: "Accomodation Per Room", type: "text", value: "2" },
    { label: "Campus Size", type: "text", value: "38 acres" },
    { label: "Cutoff 1st Round", type: "number", value: "0" },
    { label: "Cutoff 2nd Round", type: "number", value: "0" },
    { label: "Establishment", type: "text", value: "1990" },
    { label: "Laundry Charges", type: "text", value: "10-12 per cloth" },
    { label: "Total Seats", type: "number", value: "0" },
    { label: "Tuition Fee", type: "text", value: "14-15 lac.p.a" },
    { label: "Internship Stipend", type: "text", value: "10k" },
    { label: "Patient flow(per day)", type: "text", value: "1k" },
    {
      label: "Campus Facility",
      type: "text",
      value: "cafe, area for different games, etc",
    },
    { label: "Total Fee for 1st year", type: "text", value: "1k" },
    {
      label: "Outing Time with detailed explanation",
      type: "text",
      value: "",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const onChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
    setImage(file);
    setDisabled(false);
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        mt="26px"
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Flex direction="column">
              <Text
                fontSize="lg"
                fontWeight="bold"
                pb=".5rem"
                casing={"uppercase"}
              >
                Add College
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <form
              id="add-college"
              style={{ width: "100%" }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Grid templateColumns={"repeat(1,1fr)"} gap="1rem" width="100%">
                <Grid templateColumns="1fr 1fr" gap="1rem">
                  {imageUrl && (
                    <Image
                      className="rounded"
                      maxW="100%"
                      borderRadius="16px"
                      src={imageUrl}
                      alt="Preview"
                    />
                  )}
                  <Flex direction="column" gap="1rem">
                    <Button as="label">
                      Choose Photo
                      <input
                        required
                        hidden
                        type="file"
                        onChange={onChange}
                        accept="image/png, image/jpeg"
                        id="account-settings-upload-image"
                      />
                    </Button>
                    <Button
                      isLoading={isLoading}
                      disabled={disabled}
                      colorScheme="teal"
                      onClick={() => {
                        setIsLoading(true);
                        uploadImage(image, setImage, setDisabled, setIsLoading);
                      }}
                    >
                      Upload
                    </Button>
                  </Flex>
                </Grid>
                <Grid templateColumns={"3fr 1fr"} gap="1rem" width="100%">
                  <InputField
                    type="text"
                    label="Name"
                    placeholder="Name of your college"
                    value={name}
                    state={name}
                    onChange={setName}
                    disabled={false}
                    required={true}
                  />
                  <InputField
                    type="select"
                    label="State"
                    value={state}
                    state={state}
                    onChange={setState}
                    options={indianStates}
                    disabled={false}
                    required={true}
                  />
                </Grid>
                <InputField
                  type="textarea"
                  label="Description"
                  placeholder="About the college..."
                  value={des}
                  state={des}
                  onChange={setDes}
                  disabled={false}
                  required={true}
                />
                <Divider my="1rem" />

                <Grid templateColumns={"1fr 1fr 1fr"} gap="1rem" width="100%">
                  {infos.map((info_val, i) => (
                    <InputField
                      key={i}
                      type={info_val.type}
                      label={info_val.label}
                      placeholder={info_val.value}
                      state={
                        info.hasOwnProperty(info_val.label)
                          ? info[info_val.label]
                          : null
                      }
                      onChange={(e) =>
                        setInfo((prev) => ({ ...prev, [info_val.label]: e }))
                      }
                      options={info_val?.options}
                      disabled={false}
                      required={true}
                    />
                  ))}
                </Grid>
              </Grid>
            </form>
          </CardBody>
          <CardFooter>
            <Button bg="teal.400" type="submit" form="add-college">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
