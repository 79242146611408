import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsFiletypeCsv } from "react-icons/bs";
import { getCollectionRealtimeRealtime } from "utils/firebaseUtils";

import TableRow from "components/Tables/ContactRow";
import { TbDatabaseOff } from "react-icons/tb";
import { downloadCSV } from "utils/FileUtils";

export default function Contact() {
  const textColor = useColorModeValue("gray.700", "white");
  const cardColor = useColorModeValue("white", "gray.700");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const headers = {
    Name: "name",
    Phone: "phone",
    Email: "email",
    Message: "msg",
    "Submitted At": "datentime",
  };
  useEffect(() => {
    getCollectionRealtimeRealtime("contact", setData, setLoading);
  }, []);
  return (
    <>
      {loading ? (
        <Center mt="6rem">
          <Spinner size="lg" colorScheme="teal" />
        </Center>
      ) : (
        <Card
          mt="5rem"
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Flex>
              <Text fontSize="xl" color={textColor} casing="uppercase">
                These are the users who contacted you
              </Text>

              <IconButton
                ml="auto"
                onClick={() =>
                  downloadCSV(
                    data,
                    ["id", "name", "email", "msg", "datentime"],
                    "Contact.csv"
                  )
                }
                variant="ghost"
                size="lg"
                borderRadius="100%"
                icon={<BsFiletypeCsv />}
              />
            </Flex>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              {data.length > 0 ? (
                <>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      {Object.keys(headers).map((caption, idx) => {
                        return (
                          <Th color="gray.400" key={idx}>
                            {caption}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((row, i) => {
                      return (
                        <TableRow
                          col="contact"
                          key={i}
                          keys={Object.values(headers)}
                          data={row}
                        />
                      );
                    })}
                  </Tbody>
                </>
              ) : (
                <Flex
                  h="40vh"
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    sx={{
                      color: "gray.400",
                      fontSize: "4xl",
                      textAlign: "center",
                    }}
                  >
                    <TbDatabaseOff />
                  </Text>
                  <Text sx={{ color: "gray.400", fontSize: "xl" }}>
                    NO ENTRIES
                  </Text>
                </Flex>
              )}
            </Table>
          </CardBody>
        </Card>
      )}
    </>
  );
}
