import {
  Badge,
  CloseButton,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { deleteData } from "utils/firebaseUtils";

function TableRow(props) {
  const navigate = useHistory();
  const { data, keys, col } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const formattedDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString();
  };
  const toast = useToast();
  const handleDelete = () => {
    deleteData(col, data.id).then(() => {
      toast({
        position: "bottom-right",
        title: "Entry Deleted",
        variant: "subtle",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    });
  };
  const badgeColor = {
    paid: "green",
    failed: "red",
    initiated: "yellow",
    expired: "red",
    pending: "blue",
    done: "green",
    "not picked": "blue",
    "low chance": "purple",
    "high chance": "teal",
  };
  const dataToDisplay = (key) =>
    key === "datentime"
      ? formattedDate(data[key])
      : key === "msg"
      ? data[key].slice(0, 15) + "..."
      : data.hasOwnProperty(key)
      ? data[key]
      : "";
  const dataToDisplayComplete = (key) =>
    key === "datentime"
      ? formattedDate(data[key])
      : data.hasOwnProperty(key)
      ? data[key]
      : "";
  return (
    <Tr>
      {keys.map((key, i) => {
        return (
          <Td
            onClick={() => navigate.push(`/admin/edit-plan/${data.id}`)}
            key={i}
          >
            {key === "status" ? (
              <Badge
                variant="subtle"
                borderRadius="0.5rem"
                p="0.25rem 0.5rem"
                fontSize="sm"
                colorScheme={badgeColor[data?.status.toLowerCase()]}
              >
                <Text casing="uppercase">{data?.status}</Text>
              </Badge>
            ) : (
              <Tooltip
                bg="teal.400"
                placement="bottom"
                label={dataToDisplayComplete(key)}
              >
                <Text my="auto" fontSize="md" color={textColor}>
                  {dataToDisplay(key)}
                </Text>
              </Tooltip>
            )}
          </Td>
        );
      })}
      <Td>
        <CloseButton
          onClick={() => handleDelete()}
          color="red.400"
          size="sm"
          my="auto"
        />
      </Td>
    </Tr>
  );
}

export default TableRow;
