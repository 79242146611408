import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import { useState } from "react";

import InputField from "components/Forms/InputField";
import { useHistory } from "react-router-dom";
import { sendDataRealtime } from "utils/firebaseUtils";

export default function AddTestimonial() {
  const navigate = useHistory();
  const toast = useToast();
  const [data, setData] = useState({
    name: "",
    college: "",
    body: "",
    datentime: 0,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    let id = uuidv4();
    sendDataRealtime("Testimonials", id, { id: id, ...data }).then(() => {
      toast({
        position: "bottom-right",
        title: "Testimonial Created",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      navigate.replace(`/admin/testimonials`);
    });
  };
  const cardColor = useColorModeValue("white", "gray.700");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        mt="26px"
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Text
              fontSize="lg"
              fontWeight="bold"
              pb=".5rem"
              casing={"uppercase"}
            >
              Add Testimonial
            </Text>
          </CardHeader>
          <CardBody>
            <form
              id="add-testimonial"
              style={{ width: "100%" }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Grid templateColumns={"repeat(1,1fr)"} gap="1rem" width="100%">
                <Grid templateColumns={"1fr 1fr"} gap="1rem" width="100%">
                  <InputField
                    type="text"
                    label="Name"
                    placeholder="Name of the client"
                    value={data.name}
                    state={data.name}
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, name: e }));
                    }}
                    disabled={false}
                    required={true}
                  />
                  <InputField
                    type="text"
                    label="College"
                    placeholder="College Name"
                    value={data.college}
                    state={data.college}
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, college: e }));
                    }}
                    disabled={false}
                    required={true}
                  />
                </Grid>
                <Grid templateColumns={"1fr"} gap="1rem" width="100%">
                  <InputField
                    type="textarea"
                    label="Comments"
                    value={data?.body}
                    state={data?.body}
                    placeholder="About this testimonial..."
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, body: e }));
                    }}
                    disabled={false}
                    required={true}
                  />
                </Grid>
              </Grid>
            </form>
          </CardBody>
          <CardFooter>
            <Button bg="teal.400" type="submit" form="add-testimonial">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
