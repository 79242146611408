// Chakra imports
import {
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  Textarea,
  Select,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@chakra-ui/react";
function InputField({
  label,
  type,
  value,
  state = "",
  disabled = false,
  size = "md",
  placeholder = "",
  helper = null,
  onChange = () => false,
  options = [],
  required = false,
  rightElement = null,
  borderRadius = "15px",
  ...rest
}) {
  const inputType = () => {
    switch (type) {
      case null:
        return;
      case "function":
        return (
          <Select
            {...rest}
            required={required}
            borderRadius={borderRadius}
            focusBorderColor="teal.400"
            disabled={disabled}
            defaultValue={value}
            value={state}
            onChange={(e) => onChange(e.target.value)}
            // placeholder="Select option"
          >
            {options.map((func, i) => {
              return (
                <option
                  key={i}
                  name={func.name}
                  value={`{${
                    func.hasOwnProperty("args") ? func.name : func.func
                  }}`}
                >
                  {func.hasOwnProperty("args") ? func.name : func.func}
                </option>
              );
            })}
          </Select>
        );
      case "select":
        return (
          <Select
            {...rest}
            required={required}
            borderRadius={borderRadius}
            focusBorderColor="teal.400"
            disabled={disabled}
            defaultValue={value}
            value={state}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
          >
            {Array.isArray(options)
              ? options.map((type, i) => (
                  <option key={i} value={type}>
                    {Number.isInteger(type)
                      ? type
                      : type.charAt(0).toUpperCase() + type.slice(1)}
                  </option>
                ))
              : Object.keys(options).map((type, i) => (
                  <option key={i} name={type} value={options[type]}>
                    {type}
                  </option>
                ))}
          </Select>
        );
      case "switch":
        return (
          <Switch
            {...rest}
            required={required}
            colorScheme="teal"
            disabled={disabled}
            defaultChecked={value}
            value={state}
            size="sm"
            onChange={() => onChange(!value)}
          >
            {label}
          </Switch>
        );
      case "textarea":
        return (
          <InputGroup>
            <Textarea
              focusBorderColor="teal.400"
              borderRadius={borderRadius}
              type={type}
              placeholder={placeholder}
              defaultValue={value}
              onChange={(e) => onChange(e.target.value)}
              required={required}
              disabled={disabled}
            />
            <InputRightElement width="4.5rem">{rightElement}</InputRightElement>
          </InputGroup>
        );
      case "radio":
        return (
          <RadioGroup
            required={required}
            borderRadius={borderRadius}
            focusBorderColor="teal.400"
            disabled={disabled}
            defaultValue={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Select option"
          >
            <Stack>
              {options.map((type, i) => (
                <Radio colorScheme="teal" key={i} value={type}>
                  {Number.isInteger(type)
                    ? type
                    : type.charAt(0).toUpperCase() + type.slice(1)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        );
      default:
        return (
          <InputGroup>
            <Input
              size={size}
              focusBorderColor="teal.400"
              borderRadius={borderRadius}
              type={type}
              placeholder={placeholder}
              defaultValue={value}
              onChange={(e) => onChange(e.target.value)}
              required={required}
              disabled={disabled}
              {...rest}
            />
            <InputRightElement width="4.5rem">{rightElement}</InputRightElement>
          </InputGroup>
        );
    }
  };
  return (
    <FormControl>
      {type !== "switch" && <FormLabel> {label}</FormLabel>}
      {inputType()}
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
}

export default InputField;
