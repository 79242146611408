import EditPlan from "components/Forms/EditPlan";
import { DocumentIcon } from "components/Icons/Icons";
import { TbLetterA, TbLetterB, TbLetterC } from "react-icons/tb";
import PlanA from "views/Dashboard/PlanA";
import PlanB from "views/Dashboard/PlanB";
import PlanC from "views/Dashboard/PlanC";
var adminRoutes = [
  {
    path: "/edit-plan/:id",
    name: "Edit Plan Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: EditPlan,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/plan-a",
    name: "Plan A",
    rtlName: "",
    icon: <TbLetterA color="inherit" />,
    component: PlanA,
    layout: "/admin",
  },
  {
    path: "/plan-b",
    name: "Plan B",
    rtlName: "",
    icon: <TbLetterB color="inherit" />,
    component: PlanB,
    layout: "/admin",
  },
  {
    path: "/plan-c",
    name: "Plan C",
    rtlName: "",
    icon: <TbLetterC color="inherit" />,
    component: PlanC,
    layout: "/admin",
  },
];

export default adminRoutes;
