// Chakra imports
import JSZip from "jszip";
import { saveAs } from "file-saver";



  export function downloadCSV(data, keys, filename) {
    const csv = convertToCSV(data, keys);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    if (navigator.msSaveBlob) {
      // For IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");

      if (link.download !== undefined) {
        // For modern browsers
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  function convertToDate(datentime) {
    const date = new Date(datentime);
    return date.toLocaleString();
  }
  function convertToCSV(data, keys) {
    let headers = [];
    if (keys.length === 0) {
      headers = Object.keys(data[0]).join(",");
    } else {
      headers = keys.join(",");
    }
    const rows = data.map((obj) =>
      keys
        .map((val) =>
          obj.hasOwnProperty(val)
            ? val === "datentime"
              ? `"${convertToDate(obj[val])}"`
              : `"${obj[val]}"`
            : ""
        )
        .join(",")
    );
    return [headers, ...rows].join("\n");
  }

export function createZipFromFilesData(filesData, zipName) {
  const zip = new JSZip();
  for (const fileData of filesData) {
    if (fileData.hasOwnProperty("file")) {
      // console.log(fileData);
      const filePath = fileData.file.file_path;
      const relPath = filePath.substring(filePath.lastIndexOf("/") + 1);
      const fileContent = fileData.file.file_content;
      // console.log(relPath);
      zip.file(relPath, fileContent);
    } else {
      // console.log(fileData);
      const filePath = fileData.file_path;
      const relPath = filePath.substring(filePath.lastIndexOf("/") + 1);
      const fileContent = fileData.file_content;
      // console.log(relPath);
      zip.file(relPath, fileContent);
    }
  }
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `${zipName}.zip`);
  });
}
