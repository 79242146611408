import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Grid,
  Tag,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { uuidv4 } from "@firebase/util";
import InputField from "components/Forms/InputField";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import { useRouteMatch } from "react-router-dom";
import {
  deleteDataField,
  getDocumentRealtime,
  updateData,
} from "utils/firebaseUtils";

export default function EditPlan() {
  const match = useRouteMatch();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    altPhone: "",
    email: "",
    id: "",
    name: "",
    phone: "",
    plan: "",
    score: "",
    comments: "",
    status: "",
  });
  const createNewKey = () => {
    setIsLoading(true);
    let uid = uuidv4();
    updateData("plans", data.id, { key: uid }).then(() => {
      toast({
        position: "bottom-right",
        title: "New Key Generated",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    });
  };
  const delKey = () => {
    setIsLoading(true);
    deleteDataField("plans", data.id, "key").then(() => {
      toast({
        position: "bottom-right",
        title: "Key Deleted",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    });
  };
  const makeKey = () => {
    const key = uuidv4();
    updateData("plans", data.id, { key: key, status: "paid" }).then(
      toast({
        position: "bottom-right",
        title: "New Key Generated",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      })
    );
  };
  const createPaymentLink = (id, items) => {
    setIsLoading(true);
    const url = "https://payment-razor.netlify.app/.netlify/functions/pay/";
    const body = {
      amount: data.total * 100,
      currency: "INR",
      accept_partial: false,
      description: "Plan A",
      customer: {
        name: data.name,
        email: data.email,
        contact: data.phone,
      },
      notify: {
        sms: true,
        email: true,
      },
      reminder_enable: true,
      notes: {
        states: items.join(", "),
        id: id,
      },
      callback_url: "https://accidentaldoctor.in/#/home/order/",
      callback_method: "get",
    };
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, options)
      .then((response) => {
        response.json();
        toast({
          position: "bottom-right",
          title: "New link Generated",
          variant: "subtle",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    getDocumentRealtime("plans", match.params.id, setData);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    updateData("plans", data.id, data).then(() => {
      toast({
        position: "bottom-right",
        title: "Entry Saved",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setIsLoading(false);
    });
  };
  // const handleDelete = (index) => {
  //   if (index !== -1) {
  //     data.items.splice(index, 1);
  //     setData((prev) => ({ ...prev, items: data.items }));
  //   }
  // };
  const badgeColor = {
    paid: "green",
    failed: "red",
    initiated: "yellow",
    expired: "red",
  };
  const cardColor = useColorModeValue("white", "gray.700");
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        mt="26px"
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Grid templateColumns={"3fr 1fr"} gap="1rem" width="100%">
              <Text
                fontSize="lg"
                fontWeight="bold"
                pb=".5rem"
                casing={"uppercase"}
              >
                Edit Plan Details
              </Text>

              {data.plan === "A" ? (
                <Box ml="auto">
                  <Badge
                    variant="subtle"
                    borderRadius="0.5rem"
                    p="0.5rem 1rem"
                    fontSize="md"
                    colorScheme={badgeColor[data?.status]}
                  >
                    <Text casing="uppercase">{data?.status}</Text>
                  </Badge>
                </Box>
              ) : (
                <InputField
                  type="select"
                  value={data?.status}
                  state={data?.status}
                  onChange={(e) => {
                    setData((prev) => ({ ...prev, status: e }));
                  }}
                  options={[
                    "Initiated",
                    "Not Picked",
                    "Low Chance",
                    "High Chance",
                    "Done",
                  ]}
                  placeholder="Select one"
                  disabled={false}
                  required={true}
                />
              )}
            </Grid>
          </CardHeader>
          <CardBody>
            {data.plan === "A" ? (
              <form
                id="edit-plans"
                style={{ width: "100%" }}
                onSubmit={(e) => handleSubmit(e)}
              >
                <Grid templateColumns={"repeat(1,1fr)"} gap="1rem" width="100%">
                  <Grid templateColumns={"2fr 1fr"} gap="1rem" width="100%">
                    <InputField
                      type="text"
                      label="Name"
                      placeholder="Name of the client"
                      value={data.name}
                      state={data.name}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, name: e }));
                      }}
                      disabled={false}
                      required={true}
                    />
                    <InputField
                      type="select"
                      label="Plan"
                      value={data.plan}
                      state={data.plan}
                      placeholder="Choose a plan"
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, plan: e }));
                      }}
                      options={["A", "B", "C"]}
                      disabled={true}
                      required={true}
                    />
                  </Grid>
                  <Grid templateColumns={"1fr 1fr 1fr"} gap="1rem" width="100%">
                    <InputField
                      type="text"
                      label="Email"
                      placeholder="Email Address"
                      value={data.email}
                      state={data.email}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, email: e }));
                      }}
                      disabled={false}
                      required={true}
                    />
                    <InputField
                      type="number"
                      label="Phone"
                      placeholder="+91-XXX-XXX-XXXX"
                      value={data.phone}
                      state={data.phone}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, phone: e }));
                      }}
                      required={true}
                    />
                    <InputField
                      type="number"
                      label="Total"
                      value={data.total}
                      state={data.total}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, total: e }));
                      }}
                      required={true}
                    />
                  </Grid>

                  <InputField
                    type="textarea"
                    label="Comments"
                    value={data?.comments}
                    state={data?.comments}
                    placeholder="About this query..."
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, comments: e }));
                    }}
                    disabled={false}
                    required={false}
                  />
                  <Divider />
                  <Wrap spacing="10px">
                    {data.items.map((ele, i) => (
                      <WrapItem key={i}>
                        <Tag bg={"teal.700"}>
                          <TagLabel>{`${ele}`}</TagLabel>
                          {/* <TagCloseButton
                            color="red.400"
                            onClick={() => handleDelete(i)}
                          /> */}
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                  {data.hasOwnProperty("key") && (
                    <>
                      <Divider />
                      <Grid
                        templateColumns={"7fr 1fr 1fr"}
                        gap="1rem"
                        width="100%"
                      >
                        <Text my="auto" fontSize="2xl">
                          KEY: {data.key}
                        </Text>
                        <Button
                          isLoading={isLoading}
                          mt="auto"
                          leftIcon={<FiRefreshCw />}
                          bg="teal.400"
                          onClick={() => {
                            createNewKey();
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          isLoading={isLoading}
                          mt="auto"
                          leftIcon={<AiOutlineDelete />}
                          bg="red.400"
                          onClick={() => {
                            delKey();
                          }}
                        >
                          Revoke
                        </Button>
                      </Grid>
                    </>
                  )}
                  {data.hasOwnProperty("razorpay_id") &&
                    data.hasOwnProperty("razorpay_link") && (
                      <>
                        <Divider />
                        <Grid
                          templateColumns={"2fr 2fr 1fr"}
                          gap="1rem"
                          width="100%"
                        >
                          <Text my="auto" fontSize="lg">
                            ID: {data.razorpay_id}
                          </Text>

                          <Text my="auto" fontSize="lg">
                            LINK: {data.razorpay_link}
                          </Text>
                          <Button
                            isLoading={isLoading}
                            mt="auto"
                            leftIcon={<FiRefreshCw />}
                            bg="teal.400"
                            onClick={() => {
                              createPaymentLink(data.id, data.items);
                            }}
                          >
                            Reset
                          </Button>
                        </Grid>
                      </>
                    )}
                </Grid>
              </form>
            ) : (
              <form
                id="edit-plans"
                style={{ width: "100%" }}
                onSubmit={(e) => handleSubmit(e)}
              >
                <Grid templateColumns={"repeat(1,1fr)"} gap="1rem" width="100%">
                  <Grid templateColumns={"2fr 1fr 1fr"} gap="1rem" width="100%">
                    <InputField
                      type="text"
                      label="Name"
                      placeholder="Name of the client"
                      value={data.name}
                      state={data.name}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, name: e }));
                      }}
                      disabled={false}
                      required={true}
                    />
                    <InputField
                      type="number"
                      label="Score"
                      placeholder="<720"
                      value={data.score}
                      state={data.score}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, score: e }));
                      }}
                      disabled={false}
                    />
                    <InputField
                      type="select"
                      label="Plan"
                      value={data.plan}
                      state={data.plan}
                      placeholder="Choose a plan"
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, plan: e }));
                      }}
                      options={["A", "B", "C"]}
                      disabled={false}
                      required={true}
                    />
                  </Grid>
                  <Grid templateColumns={"1fr 1fr 1fr"} gap="1rem" width="100%">
                    <InputField
                      type="text"
                      label="Email"
                      placeholder="Email Address"
                      value={data.email}
                      state={data.email}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, email: e }));
                      }}
                      disabled={false}
                      required={true}
                    />
                    <InputField
                      type="number"
                      label="Phone"
                      placeholder="+91-XXX-XXX-XXXX"
                      value={data.phone}
                      state={data.phone}
                      onChange={(e) => {
                        setData((prev) => ({ ...prev, phone: e }));
                      }}
                      required={true}
                    />
                    <InputField
                      type="number"
                      label="Alt. Phone"
                      placeholder="+91-XXX-XXX-XXXX"
                      value={data.altPhone}
                      state={data.altPhone}
                      onChange={(e) => {
                        setData((prev) => ({
                          ...prev,
                          altPhone: e,
                        }));
                      }}
                    />
                  </Grid>
                  <InputField
                    type="textarea"
                    label="Comments"
                    value={data?.comments}
                    state={data?.comments}
                    placeholder="About this query..."
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, comments: e }));
                    }}
                    disabled={false}
                    required={false}
                  />
                </Grid>
              </form>
            )}
          </CardBody>
          <CardFooter>
            <Button
              isLoading={isLoading}
              bg="teal.400"
              type="submit"
              form="edit-plans"
              mr="1rem"
            >
              Save
            </Button>
            {!data.hasOwnProperty("key") && (
              <Button isLoading={isLoading} bg="teal.400" onClick={makeKey}>
                Make Key
              </Button>
            )}
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
