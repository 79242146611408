import { Center, Flex, Grid, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import NewCard from "components/Card/NewCard";
import SectionCard from "components/Card/SectionCard";
import { getDataRealtime } from "utils/firebaseUtils";

import { useRouteMatch } from "react-router-dom";

export default function College() {
  const match = useRouteMatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDataRealtime(
      "colleges",
      match.params.state,
      (e) => {
        delete e["datentime"];
        delete e["isActive"];
        setData(Object.values(e));
      },
      setLoading
    );
  }, []);

  return (
    <>
      <Flex
        flexDirection="column"
        pt={{ base: "120px", md: "5rem", lg: "5rem" }}
      >
        {loading ? (
          <Center mt="6rem">
            <Spinner size="lg" colorScheme="teal" />
          </Center>
        ) : (
          <Grid
            templateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
            templateRows={{ md: "1fr auto", lg: "1fr" }}
            gap="24px"
          >
            {data.map((row) => (
              <SectionCard
                key={row.id}
                id={row.id}
                name={row.title}
                state={match.params.state}
                datentime={row.datentime}
              />
            ))}
            <NewCard section="college" state={match.params.state} />
          </Grid>
        )}
      </Flex>
    </>
  );
}
