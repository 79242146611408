import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  Box,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Switch,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import InputField from "components/Forms/InputField";
import { useState } from "react";
import { BsCurrencyRupee, BsFillImageFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { updateDataRealtime, uploadImage } from "utils/firebaseUtils";
function StateCard({ name = "", mapLinks = {}, prices = {}, isActive=true }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPriceOpen,
    onOpen: onPriceOpen,
    onClose: onPriceClose,
  } = useDisclosure();
  const navigate = useHistory();
  const [link, setLink] = useState("");
  const [status, setStatus] = useState(isActive);
  const [price, setPrice] = useState();
  const [disabled, setDisabled] = useState(true);
  const toast = useToast();
  const cardColor = useColorModeValue("gray.200", "gray.700");
  const handleSubmitPrice = (e) => {
    e.preventDefault();
    updateDataRealtime('Prices', { [name]: price }).then(() => {
      toast({
        position: "bottom-right",
        title: "Price Saved",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      onPriceClose();
    });
  };
  const handleSubmitStatus = (e) => {
    e.preventDefault();
    updateDataRealtime(`colleges/${name}`, {'isActive':!status}).then(() => {

    setStatus((prevStatus) => !prevStatus);
      toast({
        position: "bottom-right",
        title: "Status Updated",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    updateDataRealtime(`States`, { [name]: link }).then(() => {
      toast({
        position: "bottom-right",
        title: "Image Saved",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      onClose();
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const onChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
    setLink(file);
    setDisabled(false);
  };
  return (
    <>
      <Card
        variant="elevated"
        bg={cardColor}
        cursor="pointer"
        boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
        borderRadius="15px"
        onClick={() => navigate.push(`/admin/college/${name}`)}
      >
        <CardHeader py="1rem">
          <Center>
            <Text fontSize="lg" mr="auto" casing="uppercase">
              {name}
            </Text>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
              variant="ghost"
              borderRadius="100%"
              icon={<BsFillImageFill />}
            ></IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onPriceOpen();
              }}
              variant="ghost"
              borderRadius="100%"
              icon={<BsCurrencyRupee />}
            ></IconButton>
            <Box onClick={(e) => e.stopPropagation()}>
              <Switch
                onChange={(e) => {
                  e.preventDefault();
                  handleSubmitStatus(e)
                }}
                defaultChecked={status}
              />
            </Box>
          </Center>
        </CardHeader>
        <CardBody>
          <Center>
            <Image
              pb="1rem"
              width="10rem"
              src={mapLinks.hasOwnProperty(name) ? mapLinks[name] : ""}
              alt={name}
            />
          </Center>
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Image</ModalHeader>
          <ModalCloseButton color="red.400" />
          <form onSubmit={(e) => handleSubmit(e)}>
            <ModalBody>
              {imageUrl && (
                <Image
                  className="rounded"
                  maxW="100%"
                  borderRadius="16px"
                  src={imageUrl}
                  alt="Preview"
                  p="1rem"
                />
              )}
              <Flex direction="column" gap="1rem">
                <Button as="label">
                  Choose Photo
                  <input
                    hidden
                    type="file"
                    onChange={onChange}
                    accept="image/png, image/jpeg"
                    id="map-upload-image"
                  />
                </Button>
                <Button
                  isLoading={isLoading}
                  disabled={disabled}
                  colorScheme="teal"
                  onClick={() => {
                    setIsLoading(true);
                    uploadImage(link, setLink, setDisabled, setIsLoading);
                  }}
                >
                  Upload
                </Button>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="teal" type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPriceOpen} onClose={onPriceClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name} Price</ModalHeader>
          <ModalCloseButton color="red.400" />
          <form onSubmit={(e) => handleSubmitPrice(e)}>
            <ModalBody>
              <InputField
                type="number"
                label="Price"
                placeholder="Price of State"
                value={prices.hasOwnProperty(name) ? prices[name] : null}
                state={price}
                onChange={setPrice}
                disabled={false}
                required={true}
              />
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="teal" type="submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default StateCard;
