import { createStore } from "redux";
const initialState = {
  user: {},
  Components: {},
  Forms: [],
  Tables: [],
};

function addChildAtPath(obj, path, value) {
  if (path === "") {
    return { ...obj, ...value };
  }
  const keys = path.split(".");
  const lastKeyIndex = keys.length - 1;
  let nestedObj = obj;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === lastKeyIndex) {
      nestedObj[key].child = { ...nestedObj[key]?.child, ...value };
    } else {
      nestedObj = nestedObj[key].child = { ...nestedObj[key]?.child };
    }
  }
  return nestedObj;
}

function reducer(state = initialState, action) {
  console.log(action);
  switch (action.type) {
    case "stateChange":
      return action.payload;

    case "addForm":
      return { ...state, Forms: [...state.Forms, action.payload] };

    case "addTable":
      return { ...state, Tables: [...state.Tables, action.payload] };

    case "addComponent":
      let tmp = addChildAtPath(
        state.Components,
        action.payload.path,
        action.payload.value
      );
      // console.log(tmp);
      return {
        ...state,
        Components: tmp,
      };

    case "delComponent":
      const { [action.payload]: deleted, ...newComponents } = state.Components;
      // console.log(newComponents);
      return { ...state, Components: newComponents };

    case "user":
      return { ...state, user: action.payload };

    case "logout":
      return { ...state, user: {} };

    default:
      return state;
  }
}

export const store = createStore(reducer);
