import { Center, Flex, Grid, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import StateCard from "components/Card/StateCard";
import { getDataRealtime } from "utils/firebaseUtils";

export default function Colleges() {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState({});
  const [mapLinks, setMapLinks] = useState({});
  useEffect(() => {
    getDataRealtime("Prices", "", setPrices);
    getDataRealtime("States", "", setMapLinks);
    getDataRealtime(
      "colleges",
      "",
      (e) => {
        setData(Object.keys(e));
        setStatus(e)
      },
      setLoading
    );
  }, []);
  console.log(status)
  return (
    <>
      <Flex
        flexDirection="column"
        pt={{ base: "120px", md: "5rem", lg: "5rem" }}
      >
        {loading ? (
          <Center mt="6rem">
            <Spinner size="lg" colorScheme="teal" />
          </Center>
        ) : (
          <Grid
            templateColumns={{ md: "1fr 1fr 1fr", sm: "1fr" }}
            templateRows={{ md: "1fr auto", lg: "1fr" }}
            gap="24px"
          >
            {data.map((row) => (
              <StateCard
                key={row.id}
                id={row.id}
                name={row}
                prices={prices}
                mapLinks={mapLinks}
                isActive={status[row]?.isActive}
              />
            ))}
          </Grid>
        )}
      </Flex>
    </>
  );
}
