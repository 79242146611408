// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link as ReachLink, useHistory } from "react-router-dom";
// Assets
import signInImage from "assets/img/signInImage.png";
import { useDispatch } from "react-redux";
import { loginUser } from "utils/firebaseUtils";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    loginUser(email, pass)
      .then((userCredential) => {
        // console.log(userCredential.user);
        dispatch({
          type: "user",
          payload: {
            ...userCredential.user,
          },
        });
        navigate.replace("/admin/states");
      })
      .catch((error) => {
        // console.log(error.message);
        setError(error.message);
        setIsLoading(false);
        return error.message;
      });
  };
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Enter your email and password to sign in
            </Text>
            <form onSubmit={handleLogin}>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  focusBorderColor="teal.400"
                  borderRadius="15px"
                  mb="24px"
                  fontSize="sm"
                  type="text"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your email adress"
                  size="lg"
                  color="teal.400"
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  focusBorderColor="teal.400"
                  borderRadius="15px"
                  mb="36px"
                  fontSize="sm"
                  defaultValue={pass}
                  onChange={(e) => setPass(e.target.value)}
                  type="password"
                  placeholder="Your password"
                  size="lg"
                />
                <Text color="red.400">{error}</Text>
                <FormControl display="flex" alignItems="center">
                  <Switch id="remember-login" colorScheme="teal" me="10px" />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    ms="1"
                    fontWeight="normal"
                  >
                    Remember me
                  </FormLabel>
                </FormControl>

                <Button
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                  bg="teal.300"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                  SIGN IN
                </Button>
              </FormControl>
            </form>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <Link
                  color="teal.300"
                  to="/auth/signup"
                  as={ReachLink}
                  ms="5px"
                  fontWeight="bold"
                >
                  {" "}
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
