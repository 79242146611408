import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import InputField from "components/Forms/InputField";
import { useRouteMatch } from "react-router-dom";
import { getDocumentRealtime, updateData } from "utils/firebaseUtils";

export default function EditContact() {
  const match = useRouteMatch();
  const toast = useToast();
  const [data, setData] = useState({
    id: "",
    name: "",
    phone: "",
    comments: "",
    msg: "",
    datentime: null,
  });
  useEffect(() => {
    getDocumentRealtime("contact", match.params.id, setData);
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    updateData("contact", data.id, {
      id: data.id,
      comments: data.comments,
      datentime: null,
    }).then(() => {
      toast({
        position: "bottom-right",
        title: "Details Saved",
        variant: "subtle",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    });
  };
  const cardColor = useColorModeValue("white", "gray.700");

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "5rem", lg: "5rem" }}>
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        mt="26px"
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <CardHeader py="1rem">
            <Text
              fontSize="lg"
              fontWeight="bold"
              pb=".5rem"
              casing={"uppercase"}
            >
              Edit Contact
            </Text>
          </CardHeader>
          <CardBody>
            <form
              id="edit-contact"
              style={{ width: "100%" }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Grid templateColumns={"repeat(1,1fr)"} gap="1rem" width="100%">
                <Grid templateColumns={"1fr 1fr"} gap="1rem" width="100%">
                  <InputField
                    type="text"
                    label="Name"
                    placeholder="Name of the client"
                    value={data.name}
                    state={data.name}
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, name: e }));
                    }}
                    disabled={true}
                    required={false}
                  />
                  <InputField
                    type="text"
                    label="Phone Number"
                    placeholder="Phone Number"
                    value={data.phone}
                    state={data.phone}
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, phone: e }));
                    }}
                    disabled={true}
                    required={false}
                  />
                </Grid>
                <Grid templateColumns={"1fr"} gap="1rem" width="100%">
                  <InputField
                    type="textarea"
                    label="Message"
                    value={data?.msg}
                    state={data?.msg}
                    placeholder="Message"
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, msg: e }));
                    }}
                    disabled={true}
                    required={false}
                  />
                </Grid>
                <Grid templateColumns={"1fr"} gap="1rem" width="100%">
                  <InputField
                    type="textarea"
                    label="Comments"
                    value={data?.comments}
                    state={data?.comments}
                    placeholder="About this query..."
                    onChange={(e) => {
                      setData((prev) => ({ ...prev, comments: e }));
                    }}
                    disabled={false}
                    required={false}
                  />
                </Grid>
              </Grid>
            </form>
          </CardBody>
          <CardFooter>
            <Button bg="teal.400" type="submit" form="edit-contact">
              Save
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
